<template>
  <div 
    class="filter-ctn__multi-select"
    :class="{
      'filter-ctn__multi-select_bt': borderTop
    }"
  >
    <SubTitle 
      v-if="!noTitle"
      :title="curData.name" 
    />
    <div 
      class="multi-select__content" 
      :style="{ overflow: loading ? 'hidden' : 'auto' }"
    >
      <template v-for="(item, index) in dataList">
        <SelectList 
          v-if="isCustomAttr"
          :key="index"
          :item="item"
          :language="language"
          :disabled="loading"
          :data-type="dataType"
          :is-show-title="dataList.length > 1"
          @toggle="onSelectListToggle"
          @clickItem="clickItemByList"
        />
        <SelectItem 
          v-else
          :key="index"
          :active="item.active"
          :item="item"
          :data-type="dataType"
          :disabled="loading"
          @clickItem="clickItem({ item })"
        />
      </template>
    </div>
    <div class="multi-select__operation">
      <BottomOperation
        :sum="sum"
        :language="language"
        :close-left="closeBottomLeft"
        :loading="loading"
        @reset="reset"
        @done="done"
      />
    </div>
  </div>
</template>

<script>
import BottomOperation from './UI/BottomOperation'
import SelectList from './UI/SelectList'
import SelectItem from './UI/SelectItem'
import SubTitle from './UI/SubTitle.vue'
import { emitEvent } from '../utils'
import { getIsCustomAttr } from 'public/src/pages/components/FilterBar/utils/attr.js'

export default {
  name: 'MultiSelect',
  components: {
    BottomOperation,
    SelectItem,
    SelectList,
    SubTitle
  },
  props: {
    sum: {
      type: Number,
      default: 0,
    },
    dataType: {
      type: String,
      default: ''
    },
    allData: {
      type: Array,
      default: () => []
    },
    curData: {
      type: Object,
      default: () => ({})
    },
    resetData: {
      type: Boolean,
      default: false
    },
    urlSelectedId: {
      type: Array,
      default: () => []
    },
    language: {
      type: Object,
      default: () => ({})
    },
    closeBottomLeft: {
      type: Boolean,
      default: false
    },
    noTitle: {
      type: Boolean,
      default: false
    },
    borderTop: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataList: [],
      selectedItems: [],
      allSelectedItems: [],
    }
  },
  computed: {
    selectedNum () {
      return this.curData.selectedNum
    },
    isCustomAttr () {
      return getIsCustomAttr(this.curData)
    }
  },
  watch: {
    'curData.data': {
      handler () {
        this.$nextTick(() => {
          // 回显
          this.allSelectedItems = []
          if (this.urlSelectedId?.length) {
            this.allSelectedItems = this.urlSelectedId
          }

          this.dataList = this.handleDataActive() // active项重新标记
        })
      },
      immediate: true
    },
    dataList: {
      handler (list) {
        const newList = this.isCustomAttr ? list.reduce((curr, next) => curr.concat(next.data), []) : list
        newList.forEach(item => {
          if (item.active) this.selectedItems.push(item) // 当前项选中的数量
        })
      },
      immediate: true
    },
    resetData (isReset) {
      if (isReset) {
        this.reset(true)
        this.$emit('update:resetData', false)
      }
    },
    selectedNum () {
      emitEvent(this.$parent, 'needHighlightNavBar', { highlight: !!this.selectedNum })
    },
  },
  methods: {
    clickItemByList(item) {
      this.clickItem({ item })
    },
    clickItem ({ item }) {
      let curSelectedNum = this.curData.selectedNum || 0
      const finalSelectedNum = !item.active ? curSelectedNum += 1 : curSelectedNum -= 1
      this.$set(this.curData, 'selectedNum', finalSelectedNum)  // 根据地址引用原理，触发侧边筛选数计算
      
      this.$set(item, 'active', !item.active)

      if (this.dataType === 'tsp') {
        this.selectedItems = this.dataList.filter(item => item.active).map(item => item.id)
        this.allSelectedItems = this.selectedItems
        this.multiSelectChange({ curSelectedItem: item, curSelectedNum: this.selectedItems.length })
        return
      }

      const childList = this.isCustomAttr ? this.dataList.reduce((curr, next) => curr.concat(next.data), []) : this.dataList
      this.selectedItems = childList.filter(item => item.active).map(item => item.mix_value)
      
      let _allSelectedItems = [] // 打散选中值
      this.selectedItems.forEach(item => { 
        _allSelectedItems = _allSelectedItems.concat(item.split('-'))
      })   
      this.allSelectedItems = _allSelectedItems
      this.multiSelectChange({ curSelectedItem: item, curSelectedNum: this.selectedItems.length })
    },
    reset (calledFromProps) {
      if (!this.selectedItems.length) return
      
      this.selectedItems = []
      this.allSelectedItems = []
      const childList = this.isCustomAttr ? this.dataList.reduce((curr, next) => curr.concat(next.data), []) : this.dataList
      childList.forEach(item => {
        this.$set(item, 'active', false)
      })
      this.$set(this.curData, 'selectedNum', 0)

      const params = { reset: true, curSelectedNum: 0 }
      if (calledFromProps) {
        params.from = 'propsCalled'
      }
      this.multiSelectChange(params)
    },
    done () {
      emitEvent(this.$parent, 'clickDone')
    },
    getAllSelected (curSelectedItem, isReset) {
      const key = (this.dataType === 'tsp' || curSelectedItem.nodeType == 7) ? 'id' : 'mix_value'
      const id = curSelectedItem[key]

      const allSelectedItems = this.allData.reduce((curr, next) => {
        let actives = []
        const childList = getIsCustomAttr(next) ? next.data.reduce((subCurr, subNext) => subCurr.concat(subNext.data), []) : next.data
        childList.forEach(obj => {
          const key2 = (this.dataType === 'tsp' || obj.nodeType == 7) ? 'id' : 'mix_value'
          obj.active && (obj == curSelectedItem || obj[key2] != id) && actives.push(obj)
        })

        return actives.length ? curr.concat(actives) : curr
      }, [])

      if (isReset) {
        // 过滤当前想的选中项，避免不同属性相同属性值
        const childList = this.isCustomAttr
          ? this.curData.data.reduce((subCurr, subNext) => subCurr.concat(subNext.data), [])
          : this.curData.data

        const ids = childList.map(obj => obj[key])
        return allSelectedItems.filter(obj => !ids.includes(obj[key]))
      }

      return allSelectedItems
    },
    multiSelectChange ({ reset, curSelectedNum, curSelectedItem = {}, from = '' }) {
      const params = {
        data: this.getAllSelected(curSelectedItem, reset),
        curSelectedNum,
        curSelectedItem,
        from,
        curAttr: this.curData
      }
      if (reset) {
        params.operation = 'reset'
      }
      emitEvent(this.$parent, 'multiSelectChange', params)
    },
    // 对active项重标记
    handleDataActive () {
      const list = this.curData.data || []
      if (this.allSelectedItems?.length) {
        let selectedNum = 0

        if (this.dataType === 'tsp') {
          list.forEach(item => {
            if (this.allSelectedItems.includes(item.id)) {
              this.$set(item, 'active', true)
              selectedNum += 1
            }
          })
        } else {
          const childList = this.isCustomAttr ? list.reduce((curr, next) => curr.concat(next.data), []) : list
          childList.forEach(subItem => {
            if (
              this.allSelectedItems.includes(subItem.mix_value)
               || subItem.mix_value.split('-').some(mix_value => this.allSelectedItems.includes(mix_value))
            ) {
              this.$set(subItem, 'active', true)
              selectedNum += 1
            }
          })
        }
        this.$set(this.curData, 'selectedNum', selectedNum)
      }
      return list || []
    },
    onSelectListToggle (flag) {
      emitEvent(this.$parent, 'selectListToggle', flag)
    }
  }
}
</script>

<style lang="less" scoped>
  .filter-ctn {
    &__multi-select {
      padding: 12px 12px 0;
      background: #fff;
      &_bt {
        border-top: 1px solid #e5e5e5;
      }
      .multi-select {
        &__content {
          .flexbox();
          flex-wrap: wrap;
          align-content: flex-start;
          // height: 2.86rem;
          min-height: 90px;
          max-height: 260px;
          overflow-y: auto;
          .font-dpr(24px);
        }
      }
    }
  }
</style>
